<template>
  <div
    :class="[
      'advisor-preview',
      {
        'result-wrapper': result.state && !comparison && !isNoMatch,
        'no-match': isNoMatch,
        'comparison-wrapper': !!comparison,
      },
    ]"
  >
    <template v-if="!result.state">
      <div
        v-if="themeName === 'movistar'"
        class="top-title-text"
      >
        Ayúdame a elegir el dispositivo perfecto
      </div>
      <div class="steps">
        <div class="step-wrap">
          <custom-step
            v-if="activeStep.id > 0"
            :journey="journey"
            :key="activeIndex"
            :model.sync="activeStep"
            :values.sync="selected"
            :answers="answers"
            :search-available-options="searchAvailableOptions"
          />
          <priority-step
            v-else-if="activeStep.id === 0"
            :key="activeIndex"
            :model="activeStep"
            :values.sync="priorities"
            v-bind="options"
          />
        </div>
      </div>

      <div class="progress-wrap">
        <div class="progress">
          <div
            v-for="(point, index) in steps"
            :key="index"
            class="progress-point"
            :class="{
              passed: index < activeIndex,
              active: index === activeIndex,
            }"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div v-show="!hideResults">
        <result
          v-if="!comparison && contentType !== 'Article'"
          :journey="journey"
          :model="result.model"
          :devices="result.devices"
          :compared-devices.sync="result.compared"
          :session-id="sessionId"
          v-bind="options"
        />
        <result-article
          v-if="result.state && contentType === 'Article'"
          :journey="journey"
          :model="result.model"
          :products="result.devices"
          :session-id="sessionId"
          v-bind="options"
        />
        <div
          v-else
          class="comparison-wrap"
        >
          <comparison
            :devices="result.devices"
            :numbers="numbers"
          />
        </div>
      </div>
    </template>
    <div class="controls-wrap">
      <button
        v-if="hasAnswers"
        type="button"
        class="btn btn-control btn-restart"
        @click="restart"
      >
        <span class="icon" />
        <span>{{ $t('advisor.btn_restart') }}</span>
      </button>
      <button
        v-if="activeIndex > 0"
        type="button"
        class="btn btn-control btn-prev"
        @click="prevStep"
      >
        {{ $t('advisor.btn_prev') }}
      </button>
      <button
        v-if="!result.state"
        type="button"
        class="btn btn-control btn-next"
        :disabled="!canSkip"
        @click="nextStep"
      >
        {{ $t('advisor.btn_next') }}
      </button>
      <button
        v-if="
          result.state && !comparison && !isNoMatch && contentType !== 'Article' && result.devices && result.devices.length > 1
        "
        v-show="!hideResults"
        type="button"
        class="btn btn-control btn-compare"
        @click="toggleComparison"
      >
        {{ $t('advisor.btn_compare') }}
      </button>
    </div>
  </div>
</template>

<script>
import { createGuid } from '~/utils'
import CustomStep from '~/components/sp-advisor/CustomStep.vue'
import PriorityStep from '~/components/sp-advisor/PriorityStep.vue'
import Result from '~/components/sp-advisor/Result.vue'
import Comparison from '~/components/sp-advisor/Comparison.vue'
import ResultArticle from '~/components/sp-advisor/ResultArticle.vue'

export default {
  components: { CustomStep, PriorityStep, Result, Comparison, ResultArticle },
  props: {
    journey: { type: Object, default: () => ({}) },
    id: { type: Number, required: true },
    stages: { type: Array, default: () => [] },
    options: { type: Object, default: () => ({}) },
    numbers: { type: Number, default: 3 },
    language: { type: Number, default: 1 },
    hideResults: { type: Boolean, default: false },
    contentType: { type: String, default: '' },
  },
  data () {
    const copied = JSON.parse(JSON.stringify(this.stages))
    const themeName = typeof CLIENT !== 'undefined' ? CLIENT.theme : 'generic'
    const sessionId = createGuid()
    return {
      initialSteps: copied.filter((x) => x.type !== 'result'),
      themeName: themeName || 'generic',
      result: {
        state: false,
        model: copied.find((x) => x.type === 'result'),
        devices: [],
        compared: this.options.compared || [],
      },

      comparison: false,

      activeIndex: -1,
      answers: Array(50).fill({}),
      sessionId,
      searchAvailableOptions: {}
    }
  },
  mounted () {
    this.$root.$on('app::advisor::addPriorityStep', this.addPriorityStep)
    this.$root.$on('app::advisor::removePriorityStep', this.removePriorityStep)

    this.activeIndex = 0
    this.search()
    this.callSamplingHandler('advisor')
  },
  computed: {
    isNoMatch () {
      return this.result.state && this.result.devices.length < 1
    },
    steps () {
      return this.initialSteps
    },
    activeStep: {
      get () {
        if (typeof this.steps[this.activeIndex] === 'undefined') return {}
        return this.steps[this.activeIndex]
      },
      set (value) {
        this.initialSteps.splice(this.activeIndex, 1, value)
      },
    },
    selected: {
      get () {
        const type = this.activeStep.type
        const result = this.answers[this.activeIndex]

        return (result && result[type]) || []
      },
      set (values) {
        const type = this.activeStep.type
        const model = {}
        model.id = this.activeStep.id
        model[type] = values

        this.answers.splice(this.activeIndex, 1, model)
      },
    },
    priorities: {
      get () {
        const result = this.answers[this.activeIndex]
        return (result && result.priorities) || []
      },
      set (model) {
        this.answers.splice(this.activeIndex, 1, { priorities: model })
      },
    },
    hasAnswers () {
      return this.answers.filter((x) => Object.keys(x).length > 0).length > 0
    },
    canSkip () {
      if (this.themeName === 'movistarcl') return true

      const { canSkip, selectAll } = this.activeStep
      const hasValues =
        (this.selected && this.selected.length) ||
        (selectAll && selectAll.state)
      return typeof canSkip === 'undefined' || canSkip ? true : hasValues
    }
  },
  methods: {
    nextStep () {
      this.toggleLoaderOverlay(true)
      this.search()
        .then(() => {
          this.activeIndex += 1

          if (typeof this.steps[this.activeIndex] === 'undefined') {
            this.result.state = true
          }
        })
        .finally(() => {
          this.toggleLoaderOverlay(false)
        })
    },
    prevStep () {
      if (this.result.state) {
        if (this.comparison) {
          this.comparison = false
          return
        }

        this.result.state = false
      }

      if (typeof this.steps[this.activeIndex - 1] !== 'undefined') {
        this.activeIndex -= 1
      }
    },
    restart () {
      this.activeIndex = 0
      this.answers = Array(50).fill({})
      this.result.state = false
      this.result.devices = []
    },
    toggleComparison () {
      this.comparison = !this.comparison
    },
    addPriorityStep (model) {
      const index = this.activeIndex + 1
      if (this.initialSteps[index] && this.initialSteps[index].id === 0) {
        this.initialSteps.splice(index, 1)
      }

      const insert = (arr, index, ...newItems) => [...arr.slice(0, index), ...newItems, ...arr.slice(index)]
      this.initialSteps = insert(this.initialSteps, index, model)
    },
    removePriorityStep () {
      const index = this.activeIndex + 1
      if (this.initialSteps[index] && this.initialSteps[index].id === 0) {
        this.initialSteps.splice(index, 1)

        // Remove answers by current step
        this.answers.splice(index, 1, {})
      }
    },
    async search () {
      const filtered = this.answers.filter((x) => Object.keys(x).length > 0)

      const getValues = (array, key) => {
        return [...new Set(array.map((item) => item[key]).flat())].filter((x) => typeof x !== 'undefined')
      }

      const infinity = getValues(filtered, 'infinity')
      const priorities = getValues(filtered, 'priorities')

      const interval = filtered.filter((x) => Object.keys(x).findIndex((key) => key === 'interval') > -1)

      const getIntervalId = (stageId) => {
        const stage = this.stages.find((x) => x.id === stageId)
        if (stage && stage.interval) {
          return stage.interval.id
        } else {
          return null
        }
      }

      const requestModel = {
        contentType: this.contentType,
        languageId: parseInt(this.language),
        channelId: 3, // Presale = 3
        advisorId: this.id,
        take: this.numbers || 3, // products count
        specificationSlugs: getValues(filtered, 'specifications'),
        brands: getValues(filtered, 'brands'),
        advisorParameters: infinity.map((x) => ({
          id: x,
          weight: (priorities.find((e) => e.id === x) || {}).rating || 3,
        })),
        intervalParameters: interval
          .map((x) =>
            x.interval.map((item) => ({
              id: getIntervalId(x.id),
              value: item,
            }))
          )
          .flat(),
      }

      const isLast = typeof this.steps[this.activeIndex + 1] === 'undefined'
      if (isLast) {
        const response = await this.$api.getAdvisorSearchProducts(requestModel, this.contentType)
        if (response && response.data) {
          this.result.devices = response.data
          this.$root.$emit('app::advisor::recommendations', this.result.devices)
        }
      }

      const response = await this.$api.getAdvisorSearchAvailableOptions(requestModel)
      if (response && response.data) {
        this.searchAvailableOptions = response.data || {}
      }
    },
    toggleLoaderOverlay (state) {
      this.$root.$emit('app::loader-overlay::toggle', state)
    },
    renewSession () {
      this.sessionId = createGuid()
    },
    callSamplingHandler (handler, ...params) {
      if (!this.journey) return

      const { id, contentType, language: languageId } = this.journey
      const baseParams = [id, contentType, languageId, this.sessionId]
      this.$sampling.spSample(handler, ...[...baseParams, ...params])
    },
    getSelectedOptionsNames (stage, index) {
      const optionsNames = []

      const selected = this.answers.find(x => x.id === stage.id) || {}
      if (selected) {
        switch (stage.type) {
          case 'infinity':
            Array.from(selected.infinity).forEach(value => {
              const option = Array.from(stage.infinityParams).find(option => option.value === value)
              if (option) optionsNames.push(option.text)
            })
            break
          case 'specifications':
            Array.from(selected.specifications).forEach(value => {
              const option = Array.from(stage.specificationsParams).find(option => option.value === value)
              if (option) optionsNames.push(option.text)
            })
            break
          case 'brands':
            Array.from(selected.brands).forEach(value => {
              const option = Array.from(stage.brandsParams).find(option => option.value === value)
              if (option) optionsNames.push(option.text)
            })
            break
          case 'interval':
            Array.from(selected.interval).forEach(value => {
              const option = Array.from(stage.intervalParams).find(option => option.value === value)
              if (option) optionsNames.push(option.text)
            })
            break
        }
      }

      if (stage.selectAll && stage.selectAll.state) {
        optionsNames.push(stage.selectAll.text)
      }

      return optionsNames
    }
  },
  watch: {
    activeIndex (curr, prev) {
      if (curr === -1) return

      const stepsCount = this.steps.length
      const lastIndex = stepsCount // result step index

      if (prev > curr) {
        /** when we back to the previous step, we need to re-new session and sample all data to the 'curr' index like it's a new open */
        this.renewSession()

        this.callSamplingHandler('advisor')

        Array.from(this.steps).slice(0, curr).forEach((stage, index) => {
          const stageId = stage.id

          if (!stageId) return

          this.callSamplingHandler('advisorStage', stageId)

          const optionsNames = this.getSelectedOptionsNames(stage, index)
          if (optionsNames.length) {
            this.callSamplingHandler('advisorStage', stageId, optionsNames)
          }
        })
      }

      if (curr === lastIndex) {
        const stage = this.result.model
        const stageId = stage.id
        const products = Array.from(this.result.devices)
        const productsIds = products.slice(0, this.numbers).map(p => p.id)

        this.callSamplingHandler('advisorStageFinal', stageId, productsIds)
      } else {
        const stage = this.steps[curr]
        const stageId = stage.id

        if (stageId) {
          this.callSamplingHandler('advisorStage', stageId)
        }
      }

      if (prev !== -1 && prev < curr) {
        const stage = this.steps[prev]
        const stageId = stage.id

        if (!stageId) return

        const optionsNames = this.getSelectedOptionsNames(stage, prev)
        if (optionsNames.length) {
          this.callSamplingHandler('advisorStage', stageId, optionsNames)
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .advisor-preview {
    .steps {
      min-height: 300px;
    }

    .progress-wrap {
      margin: 16px 0;
      display: flex;
      justify-content: center;

      .progress {
        display: flex;
      }

      .progress-point {
        margin: 0 4px;
        width: 8px;
        height: 8px;
        background-color: $dark;

        &.active {
          transform: scale(1.25);
        }
      }
    }

    .controls-wrap {
      margin: 16px 0;
      display: flex;
      gap: 10px;

      .btn-control {
        border: 1px solid $dark;
        border-radius: 0;
        //&.btn-compare {
        //  display: none;
        //}
      }
    }
  }
}
</style>
