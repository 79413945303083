<template>
  <div
    :class="['custom-step-block',{mounted: isMounted}]"
  >
    <div class="note-wrap">
      <button
        v-b-popover.hover="model.workNote"
        class="btn-note-tooltip"
      >
        <span class="note-icon" />
      </button>
    </div>

    <h1 class="title">
      {{ model.title }}
    </h1>

    <p
      class="description"
      v-html="model.description"
    />

    <div
      class="checkbox-buttons-wrap"
      id="checkbox-buttons-wrap"
      :class="{manufacturers: model.type === 'brands'}"
    >
      <button
        v-if="model.selectAll && model.selectAll.enabled && themeName !== 'vodafone-pt'"
        key="select-all"
        class="checkbox-button btn-select-all"
        :class="{selected: model.selectAll.state}"
        @click="selectAll"
        @click.stop="$emit('setChosenBlock', model)"
      >
        <div class="name">
          {{ model.selectAll.text }}
        </div>
        <div
          v-if="themeName !== 'movistargroup'"
          class="icon-wrap"
        >
          <div class="icon" />
        </div>
      </button>
      <button
        v-for="(btn, index) in buttons"
        :key="index"
        :name="btn.text"
        :class="[
          'checkbox-button',
          {
            selected: isSelectedButton(btn.value || btn),
            manufacturer: model.type === 'brands',
          },
        ]"
        :disabled="isDisabledBlock(btn.value)"
        @click="select(btn)"
        @click.stop="$emit('setChosenBlock', model)"
        @mouseover="mouseover(btn)"
        @mouseout="mouseout(btn)"
      >
        <div class="name">
          {{ btn.text }}
        </div>
        <div
          class="icon-wrap"
          v-if="themeName !== 'movistar' && themeName !== 'movistargroup'"
        >
          <div class="icon" />
        </div>
      </button>
      <button
        v-if="model.selectAll && model.selectAll.enabled && themeName === 'vodafone-pt'"
        key="select-all"
        class="checkbox-button btn-select-all"
        :class="{selected: model.selectAll.state}"
        @click="selectAll"
        @click.stop="$emit('setChosenBlock', model)"
      >
        <div class="name">
          {{ model.selectAll.text }}
        </div>
        <div
          v-if="themeName !== 'movistargroup'"
          class="icon-wrap"
        >
          <div class="icon" />
        </div>
      </button>
    </div>
    <div
      class="child-note--on-hover"
      v-if="childNote"
    >
      <div class="inform-icon">
        <span class="icon ss-icon ss-info" />
      </div>
      <span class="note-icon" />
      <span>{{ childNote }}</span>
    </div>
    <div
      v-if="childNotes.length > 0"
      class="child-notes--selected"
    >
      <div class="notes-list">
        <div
          v-for="(note, index) in childNotes"
          :key="index"
          class="child-note"
        >
          <div class="inform-icon">
            <span class="icon ss-icon ss-info" />
          </div>
          <p>
            {{ note }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    journey: { type: Object, default: () => ({}) },
    model: { type: Object, default: () => {} },
    values: { type: Array, default: () => [] },
    answers: { type: Array, default: () => [] },
    searchAvailableOptions: { type: Object, default: () => {} },
  },
  data () {
    const themeName = typeof CLIENT !== 'undefined' ? CLIENT.theme : 'generic'
    return {
      themeName: themeName || 'generic',
      selectAllState: false,
      childNote: '',
      childNotes: [],
      isMounted: false,
      selected: []
    }
  },
  mounted () {
    this.isMounted = true
    this.childNotes = this.buttons.filter(x => x.note && this.values.includes(x.value)).map(x => x.note)
    this.$el.scrollIntoView({ behavior: 'smooth' })
    this.selectAllState = this.model?.selectAll?.state || false

    this.buttons.forEach(x => {
      if (!this.checkDisabledBlock(x.value)) {
        this.selected.push(x)
      }
    })
  },
  computed: {
    buttons () {
      return this.model[`${this.model.type}Params`] || []
    },
    stepAnswers () {
      const step = this.answers.find((item) => item[this.model.type]) || {}
      return step[this.model.type]
    },
    isSelectedAll () {
      if (this.stepAnswers) {
        return this.stepAnswers.length === this.buttons.length
      }
      return false
    }
  },
  methods: {
    isSelectedButton (btn) {
      const currentStageAnswers = this.answers.find((item) => item[this.model.type] && item.id === this.model.id) || {}
      if (currentStageAnswers[this.model.type]) {
        return currentStageAnswers[this.model.type].includes(btn)
      }
      return false
    },
    select (btn) {
      const value = btn.value || btn
      setTimeout(() => {
        let values = [...this.values]
        const index = values.indexOf(value)
        if (index !== -1) {
          values.splice(index, 1)
        } else {
          if (this.model.multiple) values.push(value)
          else values = [value]
        }

        // const isSelectAll = this.model.selectAll && this.model.selectAll.enabled && !values.length
        this.selectAllState = false
        this.$emit('update:values', values)
        this.$emit('update:model', { ...this.model, selectAll: { ...this.model.selectAll, state: this.selectAllState } })
      }, 100)
    },
    selectAll () {
      setTimeout(() => {
        this.selectAllState = !this.selectAllState
        this.$emit('update:values', [])
        this.$emit('update:model', { ...this.model, selectAll: { ...this.model.selectAll, state: this.selectAllState } })
      }, 100)
    },
    mouseover (btn) {
      this.childNote = btn.note
    },
    mouseout (btn) {
      this.childNote = ''
    },
    isDisabledBlock (value) {
      if (this.selected.length) {
        return !this.selected.map(x => x.value).includes(value)
      } else {
        return false
      }
    },
    checkDisabledBlock (value) {
      switch (this.model.type) {
        case 'specifications': {
          const { specificationSlugs = [] } = this.searchAvailableOptions || {}
          return specificationSlugs && specificationSlugs.indexOf(value) === -1
        }
        case 'brands': {
          const { brandSlugs = [] } = this.searchAvailableOptions || {}
          return brandSlugs && brandSlugs.map(x => x.toLowerCase()).indexOf(value) === -1
        }
        case 'interval': {
          const { intervalParameterGroups = [] } = this.searchAvailableOptions || {}
          return intervalParameterGroups && intervalParameterGroups.findIndex(g => g.intervalParameterId === this.model.interval.id && g.value === value) === -1
        }
        case 'infinity': {
          const { infinityParameterIds = [] } = this.searchAvailableOptions || {}
          return infinityParameterIds && infinityParameterIds.indexOf(Number(value)) === -1
        }
        default:
          return true
      }
    },
  },
  watch: {
    values () {
      if (this.model.interrate.enabled) {
        if (this.values.length > 1 && this.model.type === 'infinity') {
          this.$root.$emit('app::advisor::addPriorityStep', {
            id: 0,
            parameters: this.buttons.filter(x => this.values.includes(x.value)),
            title: this.model.interrate.title,
            description: this.model.interrate.description,
            type: 'priority'
          })
        } else {
          this.$root.$emit('app::advisor::removePriorityStep')
        }
      }

      // Generate selected notes list
      if (this.stepAnswers) {
        this.childNotes = this.buttons.filter(x => x.note && this.stepAnswers.includes(x.value)).map(x => x.note)
      }
    }
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .custom-step-block {
    .note-wrap {
      display: none;
    }

    .title {
    }

    .description {
    }

    .checkbox-buttons-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .checkbox-button {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 8px 32px;
        line-height: 16px;
        border: 1px solid $dark;
        border-radius: 0;

        .name {
          flex: 1 0;
          text-align: center;
        }

        .icon-wrap {
          display: none;
          position: absolute;
          top: 0;
          right: 0;
          width: 32px;
          height: 100%;
          justify-content: center;
          align-items: center;

          .icon {
            position: relative;
            height: 16px;
            width: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $dark;

            &::after {
              content: '';
              position: absolute;
              width: 8px;
              height: 8px;
              background-color: $dark;
            }
          }
        }
        .brands {
          position: absolute;
          top: 7px;
          right: -4px;
          width: 33px;
          height: 100%;
          justify-content: center;
          align-items: flex-start;
          color: #007c92;
          font-size: 25px;
        }

        &:focus {
          outline: none;
        }

        &.selected {
          .icon-wrap {
            display: flex;
          }
        }
        &:disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .child-note--on-hover {
      margin: 16px 0;
    }

    .child-notes--selected {
      display: none;
    }
  }
}
</style>
