<template>
  <div :class="['result-wrap', { mounted: isMounted }]">
    <template v-if="devices.length > 0">
      <h1
        class="title"
        v-html="title"
      />
      <p
        class="description"
        v-html="model.description"
      />

      <div
        class="devices-wrap"
        :class="[
          {
            'two-devices':
              themeName === 'movistargroup' && devices.length === 2,
          },
        ]"
      >
        <div
          v-for="(d, index) in devices.slice(0, numbers)"
          :key="index"
          :class="[
            'device-card',
            { 'highest-score-device': index === 0 },
            { compared: comparedDevices.includes(d.id) },
          ]"
          @click="handleSelect(d, index)"
        >
          <div class="header">
            <span v-if="headersLabelsList[index]">{{
              headersLabelsList[index]
            }}</span>
          </div>
          <div class="image-wrap">
            <img :src="d.image">
          </div>
          <!--          <div class="btn-compare-wrap">-->
          <!--            <button-->
          <!--              v-if="themeName !== 'vodafone' && themeName !== 'movistar'"-->
          <!--              type="button"-->
          <!--              class="btn btn-compare"-->
          <!--              @click.stop="handleCompare(d)"-->
          <!--            >-->
          <!--              <span>-->
          <!--                <template v-if="!comparedDevices.includes(d.id)">Compare</template>-->
          <!--                <template v-else>Added for comparison</template>-->
          <!--              </span>-->
          <!--            </button>-->
          <!--          </div>-->
          <div class="name">
            {{ d.name }}
          </div>
          <template v-if="d.eshopLink || ['vfes', 'telstrau'].includes(clientName)">
            <a
              target="_blank"
              :href="
                d.eshopLink
                  ? getEShopLink(d.eshopLink)
                  : `/${d.manufacturer.toLowerCase()}/${d.slug}`
              "
              class="buy-device-link"
            >
              <button class="btn-buy-device">
                <template v-if="['vfes', 'vfpt'].includes(clientName)">
                  {{ model.buttonText || $t('advisor.ver_mas') }}
                </template>
                <template v-else>
                  <template
                    v-if="themeName === 'three' || themeName === 'vodafone' || themeName === 'telstrau'"
                  >
                    {{ $t('advisor.buy_now') }}
                  </template>
                  <template v-else>
                    {{ $t('advisor.want_it') }}
                  </template>
                </template>
              </button>
            </a>
          </template>
        </div>
      </div>
      <slot name="button-compare" />
    </template>
    <template v-else-if="themeName === 'vodafone-pt' || themeName === 'telstrau'">
      <div>
        <div class="bell" />
        <p
          class="text-no-matching"
          v-html="$t('advisor.text_no-matching-2')"
        />
        <p
          class="text-no-matching-requirements"
          v-html="$t('advisor.text_no-matching-2-requirements')"
        />
        <button
          class="btn-start-over"
          v-html="$t('advisor.btn_restart')"
          @click="handleStartOver"
        />
      </div>
    </template>
    <template v-else>
      <div>
        <div class="bell" />
        <p
          class="text-no-matching"
          v-html="$t('advisor.text_no-matching')"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data () {
    const themeName = typeof CLIENT !== 'undefined' ? CLIENT.theme : 'generic'
    const clientName = typeof CLIENT !== 'undefined' ? CLIENT.name : ''
    return {
      themeName: themeName || 'generic',
      clientName: clientName || '',
      isMounted: false,
    }
  },
  props: {
    journey: { type: Object, default: () => ({}) },
    model: { type: Object, default: () => ({}) },
    devices: { type: Array, default: () => [] },
    comparedDevices: { type: Array, default: () => [] },
    numbers: { type: Number, default: 3 },
    headers: { type: Array, default: () => [] },
    sessionId: { type: String, default: undefined },
  },
  mounted () {
    this.isMounted = true
    this.$root.$on(
      'app::advisor::device::remove-device-from-comparison',
      this.handleRemoveDeviceFromCompare,
    )
    this.$root.$on(
      'app::advisor::device::remove-all-devices-from-comparison',
      this.handleRemoveAllDevicesFromCompare,
    )
    this.$el.scrollIntoView({ behavior: 'smooth' })
    this.$root.$emit('app::advisor::device::compare', this.devices)
    this.$root.$emit('app::advisor::device::setDevices', this.devices)
  },
  beforeDestroy () {
    this.$root.$off(
      'app::advisor::device::remove-device-from-comparison',
      this.handleRemoveDeviceFromCompare,
    )
    this.$root.$off(
      'app::advisor::device::remove-all-devices-from-comparison',
      this.handleRemoveAllDevicesFromCompare,
    )
  },
  computed: {
    title () {
      const device = this.devices[0] || {}
      const template = `<span class="device-name">${device.name}</span>`

      // {device-name} key are using in the old version of SP Advisor
      return this.model.title
        .replace('{device-name}', template)
        .replace('{product-name}', template)
    },
    headersLabelsList () {
      return this.headers.length > 0
        ? this.headers
        : [
          this.$t('advisor.label_result-device-1'),
          this.$t('advisor.label_result-device-2'),
          this.$t('advisor.label_result-device-3'),
        ]
    },
  },
  methods: {
    handleCompare (device) {
      const copied = JSON.parse(JSON.stringify(this.comparedDevices))

      const id = device.masterId || device.id
      const index = this.comparedDevices.findIndex((x) => x === id)
      if (index > -1) {
        copied.splice(index, 1)
      } else {
        copied.push(id)
      }

      this.$emit('update:comparedDevices', copied)
      this.$root.$emit('app::advisor::device::compare', device)
    },
    handleSelect (device, index) {
      this.$root.$emit('app::advisor::device::select', device)

      this.sampleBuyBtnClick(device.id, index)
    },
    handleRemoveDeviceFromCompare (device) {
      const copied = JSON.parse(JSON.stringify(this.comparedDevices))

      const id = device.masterId || device.id
      const index = copied.findIndex((x) => x === id)
      if (index > -1) {
        copied.splice(index, 1)
      }

      this.$emit('update:comparedDevices', copied)
    },
    handleRemoveAllDevicesFromCompare () {
      this.$emit('update:comparedDevices', [])
    },
    handleStartOver () {
      this.$root.$emit('app::advisor::restart')
    },
    getEShopLink (link) {
      if (this.themeName === 'movistarcl') {
        const url = new URL(link)
        !url.searchParams.has('utm_campaign') &&
          url.searchParams.append('utm_campaign', 'RecomendadorEquipos')
        !url.searchParams.has('utm_source') &&
          url.searchParams.append('utm_source', 'APP')
        !url.searchParams.has('utm_medium') &&
          url.searchParams.append('utm_medium', 'SPAdvisor')
        return url.toString()
      }
      return link
    },
    sampleBuyBtnClick (productId, index) {
      const { id, contentType, language: languageId } = this.journey
      this.$sampling.spSample(
        'advisorBuyButtonClick',
        id,
        contentType,
        languageId,
        this.sessionId,
        productId,
        index + 1,
      )
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/scss/mixins.scss';
@include sp-content-module {
  .result-wrap {
    .title {
      .device-name {
        font-weight: 600;
      }
    }
    .description {
    }
    .devices-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .device-card {
        flex: 1;
        padding: 16px;
        text-align: center;
        border: 1px solid $dark;
        cursor: pointer;

        .header {
          margin-bottom: 8px;
        }

        .image-wrap {
          height: 180px;

          img {
            max-height: 100%;
            max-width: 50%;
            margin: auto;
          }
        }

        .btn-compare-wrap {
          margin: 8px 0;
          .btn-compare {
            font-size: 12px;
          }
        }

        .btn-buy-device {
          display: none;
        }

        .name {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
